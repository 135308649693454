import { Link } from "@StarberryUtils"
import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import './Contact.scss'
import PlayVideo from "@Components/Play/PlayVideo";
import { CONTACT_PAGE_URL } from "@Components/common/site/constants";
import ContactForm from "@Components/forms/general-enquiry"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
const ContactFormPage = (props) => {
    const [isPlay, setPlay] = useState(false);
    let processedImages = JSON.stringify({});
    if (props.contactData?.imagetransforms?.Image_Transforms) {
        processedImages = props.contactData?.imagetransforms.Image_Transforms;
    }
    return (
        <div className="contact-page contact-form-bg">
            <Container>
                <Row>
                    <Col lg="7">
                        <div className="d-lg-block padding-right">

                            <h1 className="contact-h1"><Link to={CONTACT_PAGE_URL.alias}>Back to Contact Bentley Hurst</Link></h1>
                            <h2 className="contact-h2"><Link to={CONTACT_PAGE_URL.alias}>Contact Manchester Estate agents</Link></h2>
                            <ContactForm showVideo={true} to_email_id={process.env.MAIL_TO} isContactformpage={true}/>
                            <p> If you're looking for a <a href="/property/for-sale/in-manchester/">property for sale in Manchester</a> or a <a href="/property/to-rent/in-manchester/">property for rent in Manchester</a>, our experienced <a href="/">estate agents in Manchester</a> are here to assist you with all your requirements.</p>
                        </div>
                    </Col>

                    <Col lg="5" className="pr-0 fixed-right d-lg-block">
                        <ImageTransform imagesources={props.contactData?.Image?.url} renderer="bgImg"
                            imagename='contact.Image.bgimg'
                            attr={{ alt: '', className: 'contact-bg' }}
                            imagetransformresult={processedImages} id={props.contactData?.id} >
                            {props?.contactData?.Embed_Video_URL ?
                                <strong className="play-btn" onClick={e => { setPlay(true) }} ><i className="icon-play"></i></strong>
                                : null}
                        </ImageTransform>
                    </Col>

                </Row>
                
            </Container>
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={props?.contactData?.Embed_Video_URL}
                isAutoPlay={1}
            />
        </div>


    )
}



export default ContactFormPage
